@import "../../../styles/variable.scss";

.profile {
  .main-content {
    padding: 30px;
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 1.125rem;
    }

    p {
      color: $primary-color;
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 16px;
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
    display: flex;
    background: rgba(0, 116, 178, 0.2);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
    }
  }

  &__cardleft {
    display: flex;
    align-items: center;
  }
  &__content {
    margin-left: 10px;
  }

  // input data

  &__inputdata {
    margin-top: 30px;
    padding: 24px;
    border: 1px solid $tertiary-lightGray-color;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  &__inputicon {
    display: flex;
    background: transparent;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: $primary-color;
  }
  &__inputcontent {
    padding-left: 10px;
    margin-bottom: 0;
    dd {
      margin: 5px 0 0 0 !important;
      color: $tertiary-dark-color;
      font-weight: 600;
      font-size: 1rem;
      margin-top: 3px;
      display: block;
      line-height: 16px;
      word-break: break-all;
    }
    dt {
      color: $tertiary-dark-color;
      font-size: 1rem;
      font-weight: 400;
      line-height: 16px;
      display: block;
    }
  }

  &__address__sec {
    & svg {
      fill: #02659b;
      width: 22px;
    }
  }
}

.profileDlList {
  .dl_tile_list {
    margin-top: 30px;
    .tile_class {
      padding-left: 60px;
      box-shadow: 0 0 0;
      width: 49%;
      .dt_icon {
        background-color: transparent;
        width: auto;
        height: auto;
        color: #0074b2;
        svg {
          width: 25px;
          height: auto;
        }
      }
      dd {
        margin: 5px 0 0 0 !important;
        color: $tertiary-dark-color;
        font-weight: 600;
        font-size: 1rem;
        margin-top: 3px;
        display: block;
        line-height: 16px;
        word-break: break-all;
      }
      dt {
        color: $tertiary-dark-color;
        font-size: 1rem;
        font-weight: 400;
        line-height: 16px;
        display: block;
        margin-bottom: 0;
      }
    }
  }
}

// Responsive Style
@media screen and (max-width: 992px) {
  .profile {
    &__card {
      flex-wrap: wrap;
      .btn {
        width: 100%;
        margin-top: 30px;
      }
    }
    &__cardleft {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .profileDlList {
    .dl_tile_list {
      .tile_class {
        margin-bottom: 15px;
      }
    }
  }
  .profile {
    &__card {
      flex-wrap: wrap;
      .btn {
        width: 100%;
        min-width: fit-content;
      }
    }
  }
}
