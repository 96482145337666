@import "../../../styles/variable.scss";

.dashboardCards {
  .card-props {
    padding: 20px;
    min-height: 110px;
  }
}

.chart_container {
  height: 500px;
  position: relative;
  margin-bottom: 1%;
  padding: 1%;
}

@media screen and (max-width: 320px) and (max-height: 256px) {
  .chart_container {
    height: 500px;
    position: relative;
    margin-bottom: 1%;
    padding: 1%;
    width: 295px;
  }
}

// Responsive

@media screen and (min-width: 1366px) {
  .dashboardCards {
    .col-12:nth-child(7),
    .col-12:nth-child(8),
    .col-12:nth-child(9),
    .col-12:nth-child(10) {
      width: 25%;

      .cardTitle {
        dt {
          font-size: 1rem;
          line-height: 18px;
        }
      }
    }
  }
}
