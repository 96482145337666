@import "../../../styles/variable.scss";

.billing {
  background-color: $bg-white;
  border: 1.5px solid $color-light-300;
  box-shadow: 0px 4px 24px $box-shadow;
  border-radius: 8px;

  &__header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__filter {
      flex: 0 0 300px;
      max-width: 300px;

      .inputRow {
        display: flex;
        align-items: center;
        margin: 0;
        label {
          white-space: nowrap;
          margin-right: 15px;
          margin-bottom: 0 !important;
          font-size: 1rem;
        }

        .form-control {
          min-height: 55px;
        }
      }
    }

    .wallet-section {
      h2 {
        margin-right: 10px;
      }
    }
  }

  //   content

  &__content {
    padding: 0 24px 24px 24px;

    .planCard {
      border: 1px solid $tertiary-lightGray-color;
      border-radius: 8px;
      padding: 24px;
      .row {
        align-items: center;
      }
      &__buttons {
        display: flex;
        justify-content: end;
        align-items: center;
      }

      &__description {
        display: flex;
        flex-direction: column;
        h3 {
          color: $color-dark-300;
          font-size: 0.875rem;
          line-height: 22px;
          text-transform: uppercase;
          margin: 0;
        }
        p {
          font-size: 1.25rem;
          font-weight: 700;
          margin: 5px 0 5px 0;
          color: $tertiary-dark-color;
        }
        span {
          color: $color-dark-300;
          font-size: 0.875rem;
          line-height: 22px;
        }
        .desc_msg {
          color: $error-msg-color;
        }
      }
    }

    // paymentDetails
    .paymentDetails {
      border: 1px solid $tertiary-lightGray-color;
      border-radius: 8px;
      padding: 24px;

      &__content {
        h3 {
          font-size: 1.25rem;
          font-weight: 700;
          margin: 0;
          color: $tertiary-dark-color;
        }
        p {
          color: $color-dark-300;
          font-size: 1.25rem;
          line-height: 26px;
          margin: 0;
          b {
            color: $tertiary-dark-color;
          }
        }
      }

      &__descrp {
        margin: 26px 0;

        span {
          &:nth-child(2) {
            margin: 0 5px;
          }
        }
      }

      &__billingcontent {
        position: relative;
        .editlink {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 1.125rem;
          color: $primary-color;
          background: none !important;
          border: 0 !important;
        }
        h3 {
          font-size: 1.25rem;
          font-weight: 700;
          margin: 0 0 26px 0;
          color: $tertiary-dark-color;
        }

        .billingInfo {
          margin: 8px 0 0 0;
          label {
            font-size: 1.25rem;
            line-height: 26px;
            color: $tertiary-dark-color;
          }
          p {
            margin: 0;
            font-size: 1.25rem;
            line-height: 26px;
            color: $color-dark-300;
          }
        }
      }
    }
  }
}

.editlink {
  font-size: 1.125rem;
  color: $primary-color;
  background: none !important;
  border: 0 !important;
}
// Subscription Plan

.subscription {
  background-color: $bg-white;
  border: 1.5px solid $color-light-300;
  box-shadow: 0px 4px 24px $box-shadow;
  border-radius: 8px;
  padding: 24px;
  margin-top: 30px;

  .subscriptionPlan {
    margin-top: 30px;
    &__inner {
      padding: 50px;
      min-height: 350px;
      line-height: normal;
      // margin-top: 30px;
      margin: 0 !important;
      padding-top: 90px;
    }
    .cs__badge {
      font-size: 0.875rem;
      border: 1px solid $tertiary-dark-color;
      font-weight: 500;
      padding: 5px 10px;
      box-sizing: border-box;
      border-radius: 30px;
      margin-bottom: 8px;
      display: inline-block;
      color: $tertiary-dark-color;
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .renewSubscription {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      color: $error-msg-color;
    }
    h3 {
      margin-top: 20px;
      font-size: 2rem;
    }
  }

  // .subscriptionPlan {
  //   border: 1px solid $tertiary-lightGray-color;
  //   border-radius: 14px;
  //   padding: 50px;
  //   margin: 30px 0 0 0;
  //   text-align: center;
  //   cursor: pointer;
  //   min-height: 424px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: column;

  //   &__header {
  //     h3 {
  //       font-weight: 700;
  //       font-size: 1.50rem;
  //       line-height: 24px;
  //       color: $tertiary-dark-color;
  //       margin-top: 11px;
  //     }
  //     p {
  //       margin: 8px 0 0 0;
  //       font-size: 1.15rem;
  //       line-height: 24px;
  //       color: $color-dark-300;
  //     }
  //   }

  //   .planBtn {
  //     margin: 35px auto auto auto;
  //   }

  //   .cs__badge {
  //     font-size: 0.875rem;
  //     border: 1px solid $tertiary-dark-color;
  //     font-weight: 500;
  //     padding: 5px 10px;
  //     box-sizing: border-box;
  //     border-radius: 30px;
  //     margin-bottom: 8px;
  //     display: inline-block;
  //     color: $tertiary-dark-color;
  //     display: none;
  //   }

  //   &__content {
  //     margin-top: 35px;
  //     h2 {
  //       font-size: 3rem;
  //       color: $tertiary-dark-color;
  //       font-weight: 700;
  //       letter-spacing: -1.584px;
  //     }
  //     span {
  //       margin: 8px 0 0 0;
  //       font-size: 1rem;
  //       line-height: 24px;
  //       color: $color-dark-300;
  //     }
  //   }
  // }

  // .planSelected {
  //   border-color: $primary-color;
  //   background: rgba(0, 116, 178, 0.1);
  //   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  //   .cs__badge {
  //     display: inline-block;
  //   }
  //   .planBtn {
  //     background-color: $primary-color;
  //     color: $bg-white !important;
  //   }
  // }
}

// addPlan

.addPlan {
  border: 1px solid $tertiary-lightGray-color;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 30px;
  background-color: $bg-white;
  .planCard {
    border: 1px solid $tertiary-lightGray-color;
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;
    .row {
      align-items: center;
    }
    &__buttons {
      display: flex;
      justify-content: end;
      align-items: center;
    }

    &__description {
      h3 {
        color: $color-dark-300;
        font-size: 0.875rem;
        line-height: 22px;
        text-transform: uppercase;
        margin: 0;
      }
      p {
        font-size: 1.25rem;
        font-weight: 700;
        margin: 5px 0 5px 0;
        color: $tertiary-dark-color;
        line-height: normal;
      }
      span {
        color: $error-msg-color;
        font-size: 0.875rem;
        line-height: 17px;
        display: block;
        font-weight: 600;
      }
    }
  }
}

.cardDetailForm {
  border: 1px solid $tertiary-lightGray-color;
  border-radius: 8px;
  padding: 24px;
  margin-top: 30px;
  margin-bottom: 0;
  background-color: $bg-white;
  h4 {
    font-weight: 700;
    font-size: 1.375rem;
  }
  .paymentbtn {
    margin-left: 5px;
  }

  .braintree-dropin {
    margin-top: 0 !important;
  }
}

// Responsive
@media screen and (max-width: 1366px) {
  .billing {
    .paymentDetails {
      p {
        font-size: 1rem;
        line-height: 22px;
      }

      &__billingcontent {
        .billingInfo {
          label,
          p {
            font-size: 1rem;
            line-height: 22px;
          }
        }
      }
    }
  }

  .subscriptionPlan {
    &__inner {
      padding-top: 80px !important;
    }
    span.cs__badge {
      min-width: 120px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .billing {
    &__header {
      .mainTitle {
        margin-bottom: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .subscription {
    .planSelected {
      padding: 50px 15px;
    }
  }

  .billing {
    &__header {
      flex-wrap: wrap;
      h2.mainTitle {
        width: 100%;
        margin-bottom: 30px !important;
      }
    }
    .planCard {
      &__buttons {
        justify-content: start;
        margin-top: 24px;
      }
    }

    .paymentDetails {
      &__billingcontent {
        margin-top: 40px;
        border-top: 1px solid #ddd;
        padding-top: 15px;

        .editlink {
          top: 15px;
        }
      }
    }
  }

  .subscription {
    .subscriptionPlanBox {
      flex-wrap: wrap;
      .subscriptionPlanBoxCol {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .billing {
    .planCard {
      &__buttons {
        justify-content: start;
        margin-top: 24px;
      }
    }

    .paymentDetails {
      &__billingcontent {
        margin-top: 0;
        border-top: 0;
        padding-top: 0;

        .editlink {
          top: 0;
        }
      }
    }
  }
  .addPlan {
    .planCard__buttons {
      justify-content: start !important;
      .btn {
        margin-left: 0 !important;
        margin-top: 15px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .billing {
    .paymentDetails {
      &__billingcontent {
        margin-top: 30px;
        border-top: 1px solid #ddd;
        padding-top: 30px;

        .editlink {
          top: 30px;
        }
      }
    }
    &__header {
      &__filter {
        flex: 0 0 100%;
        max-width: 100%;
        .inputRow {
          flex-wrap: wrap;
          label {
            margin-bottom: 8px !important;
          }
          select {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .billing {
    .planCard {
      &__buttons {
        flex-wrap: wrap;
        .btn {
          width: 100%;
          margin: 8px 0 0 0 !important;
        }
      }
    }
  }
}
