

$primary-font: "Inter", sans-serif !important;
$primary-color: #0074B2;
$orange-dark-color: #D26B1B;
$orange-light-color: #FFEADA;
$tertiary-dark-color: #10232E;
$tertiary-gray-color:#5A626B;
$tertiary-lightGray-color:#E4E6E9;
$body-bg: #F7FAFC;
$bg-white: #ffffff;
$sub-header: #6B6B6B;

$error-msg-color: #D90000;
$alert-error:#721c24;
$alert-background-error:#f8d7da;
$alert-border-error: #f5c6cb;
$alert-success:#155724;
$alert-background-success:#d4edda;
$alert-success-border: #c3e6cb;

$label-color : #10232E;
// Heading Fonts

$button-blue:  #0074B2;
$button-blue-hover: #02659b;

// Badge Inative
$badge-inactive-bg: #E4E6E9;
$badge-inactive-color: #5A626B;


// Badge active
$badge-active-bg:  rgba(14, 178, 0, 0.1);
$badge-active-color: #097400;


// upload Button
$upload-bg: rgba(0, 116, 178, 0.05);


// status Success 
$status-success-bg: rgba(14, 178, 0, 0.1);
$status-success-color: #097400;

// Status In-Progress
$status-inprogress-bg: rgba(0, 116, 178, 0.1);
$status-inprogress-color: #004B73;

// Status cancel
$status-cancel-bg:  rgba(217, 0, 0, 0.1);
$status-cancel-color: #740000;


// Button danger
$danger-button-bg : #A80000;
$danger-button-hover-bg : #970606;


// Button white
$white-button-bg : #fff;

$border-color: rgba(90, 98, 107, 0.25);
$box-shadow: rgba(0, 0, 0, 0.06);

$color-light-300: #e4e6e9;
$color-light-400: #f2f8fb;
$color-light-500: #cdcdcd;
$color-light-600: #c4c4c4;
$color-dark-300: #5a626b;

// tooltip
$tooltip-border: #dadada;
$tooltip-bg: #f2f2f2;

$red-border: rgba(217, 0, 0, 0.2);

// input
$input-bg: #E6E8EB;
$input-border-btm: #767D85;


// plan card

$planBg : #ECEDF0;
$planBorder: rgba(90, 98, 107, 0.7);


