@import "../../../styles/variable.scss";

.manageHoliday {
  &__card {
    background-color: $bg-white;
    border: 1.5px solid $color-light-300;
    box-shadow: 0px 4px 24px $box-shadow;
    border-radius: 8px;
    margin-bottom: 30px;

    &__header {
      padding: 30px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__content {
      padding: 0 24px 24px 24px;
    }

    .btn-trash {
      color: $error-msg-color;
      background: $status-cancel-bg;
      border: 1px solid $red-border;
      border-radius: 6px;
      width: 35px;
      height: 35px;
      margin-left: 10px;
    }
  }
}
