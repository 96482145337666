@import "styles/variable.scss";

.total_page_count {
  display: none;
}

.dataTables_wrapper {
  position: relative;

  .table_style{
    .no-record-main{
      padding-top: 50px;
    }
   
  }
  .customTable {
    margin-top: 0px;

    .btn-table {
      background-color: transparent !important;
      padding: 0 !important;
      border: 0;
      color: $tertiary-gray-color;

      .dropdown-menu {
        a {
          padding: 12px !important;
          font-size: 1rem;
        }
      }

      &:after {
        display: none;
      }
    }

    .border-btn {
      border: 1px solid $tertiary-lightGray-color;
      width: 36px;
      height: 36px;
      border-radius: 8px;

      svg {
        margin: 0 !important;
      }
    }

    thead {
      th {
        background: #f7f7f8;
        font-size: .875rem;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
        border-bottom: 0;
        padding: 14px 15px;
        color: $tertiary-gray-color;
        white-space: nowrap;
        line-height: normal;

        button {
          font-weight: 600;
          color: $tertiary-gray-color;
          padding-right: 20px;
          font-size: 0.9rem;
        }
      }
    }

    tbody {
      th {
        font-size: 0.9rem;
        line-height: 20px;
        border-bottom-color: $tertiary-lightGray-color;
        vertical-align: middle;
        padding: 16px 15px;
      }

      td {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 20px;
        padding: 16px 15px;
        color: $tertiary-dark-color;
        border-bottom-color: $tertiary-lightGray-color;
        vertical-align: middle;
        word-break: break-all !important;

        .user-group {
          display: flex;
          align-items: center;
          // .dropdown-menu {
          //   flex-wrap: wrap;
          // }
        }

        a {
          text-decoration: none;
        }

        .table-picture {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }

  .dataTables_info {
    font-size: 0.9rem;
    font-weight: 400;
    color: #10232e;
  }

  .dataTables_paginate {

    .paginate_button {
      border: 0;
      outline: none;
      background-color: transparent !important;
    }

    .page-item {
      button {
        border: 0;
        color: $tertiary-gray-color;
        cursor: pointer;
        font-size: 1rem;
        text-decoration: none;
        outline: none !important;
        box-shadow: 0 0 0 !important;

        &:hover {
          background-color: transparent !important;
          color: $primary-color;
        }
      }

    }

    .paginate_button.disabled {
      &:after {
        border-color: #ccc;
      }
    }

    .paginate_button {
      color: $tertiary-gray-color;
      font-size: 1rem;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: $primary-color;

      }
    }

    .disabled {
      color: #ccc !important;
      pointer-events: none;
    }



    .dt-link {
      width: 36px;
      height: 36px;
      color: $tertiary-gray-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 4px;
      margin-right: 13px;
      cursor: pointer;
      border: 0;
      background-color: transparent;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $primary-color;
      }
    }

    .dt-item.active {

      .dt-link {
        background-color: $primary-color;
        color: $bg-white;
        font-weight: 800;
        text-decoration: underline;
      }

    }

    .current {
      background-color: $primary-color;
      color: $bg-white !important;
      font-weight: 800;
    }

  }



  .dataTables_length {
    position: absolute;
    bottom: 20px;
    left: 24px;

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 190px;
      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
      color: $tertiary-dark-color;

      select {
        order: 2;
        margin-left: 5px;
        background: $bg-white url(../../assets/images/selectDropdown.svg) no-repeat;
        border: 1px solid $tertiary-lightGray-color;
        border-radius: 8px;
        padding: 6px 30px 6px 20px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 0.875rem;
        color: $tertiary-gray-color;
        min-height: 36px;
        appearance: none;
        background-position: 81% center;
      }
    }
  }

  .table_header_data {
    margin-top: -70px;
    padding: 0 24px;
  }

  .dataTables_filter {
    position: relative;

    label {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 26px;
      color: $tertiary-dark-color;

      input {
        margin-left: 10px;
        background: #e6e8eb;
        min-height: 52px;
        border-radius: 8px;
        border-top: transparent;
        border-top-width: initial;
        border-top-style: initial;
        border-top: 1.5px solid transparent;
        border-left: 1.5px solid transparent;
        border-right: 1.5px solid transparent;
        border-bottom: 1.5px solid #767d85;
        outline: none !important;
        box-shadow: 0 0 0 !important;
        font-size: 1rem;
        color: $tertiary-dark-color;
        padding: 6px 90px 6px 20px;
        min-width: 322px;

        &:focus {
          background-color: #fff;
          border-color: $primary-color !important;
          transition: border 0.3s ease-out;
        }
      }
    }
  }

  .filterSearch{
    .inputRow {
      display: flex;
      align-items: center;
      margin: 0 !important;
      label{
        white-space: nowrap;
        margin: 0 8px 0 0 !important;
        font-size: 1.125rem;
      }

      .form-control{
        min-height: 52px;
        font-size: 0.875rem;
      }
    }
  }
}

.custom-btn {
  position: absolute;
  top: -68px;
  right: 24px;
}


.table_search {
  background: #e6e8eb;
  border: 1.5px solid transparent;
  border-bottom-color: #767d85;
  border-radius: 8px;
  box-shadow: 0 0 0 !important;
  color: #10232e;
  font-size: 1rem;
  margin-left: 10px;
  min-height: 52px;
  min-width: 322px;
  outline: none !important;
  padding: 6px 90px 6px 20px;
}

.clear,
button.searchbtn {
  background: transparent;
  // color: #5a626b;
  height: 35px;
  position: absolute;
  top: 8px;
  width: 40px;
  z-index: 10;
}

button.sorting_button {
  border: 0;
  padding: 0;
  background: transparent;
  text-transform: uppercase;
}

.my-custom-div {
  overflow-x: auto;
  min-height: 200px !important;
}

button.searchbtn {
  position: absolute;
  right: 7px;
  top: 8px;
  border: 0;
  background: transparent;
  width: 40px;
  height: 35px;
  border-left: 1px solid rgba(90, 98, 107, 0.25);
  color: #5a626b;
}

.clear {
  position: absolute;
  right: 50px;
  top: 8px;
  border: 0;
  background: transparent;
  width: 40px;
  height: 35px;
  color: #5a626b;
  z-index: 99;
}

.disabled {
  span {
    color: #aaa
  }

  svg {
    color: #aaa
  }
}

/* Disable browser close icon for Chrome */
.dataTables_filter input[type="search"]::-webkit-search-decoration,
.dataTables_filter input[type="search"]::-webkit-search-cancel-button,
.dataTables_filter input[type="search"]::-webkit-search-results-button,
.dataTables_filter input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.dataTables_wrapper .dataTables_filter button.clear {
  outline: none;
  display: none;
}

.dataTables_filter input:not(:placeholder-shown)+button.clear {
  display: block !important;
}

.dataTables_filter input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}

.dataTables_filter input::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}

.dataTables_filter input:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}

.dataTables_filter input:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}

.table th svg {
  width: 13px;
  height: auto;
}

.table th svg path {
  stroke-width: 2.2px;
}

.table th svg {
  fill: #898585;
}

.sorting {
  svg {

    fill: $tertiary-dark-color !important;

  }

}

.pagination_con {
  padding: 0 24px 24px 24px;
}

.pagination-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px 24px 24px;
}

.dt-length {
  span {
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: $tertiary-dark-color;
  }

  select.form-select {
    margin-left: 5px;
    background: $bg-white url(../../assets/images/selectDropdown.svg) no-repeat;
    border: 1px solid $tertiary-lightGray-color;
    border-radius: 8px;
    padding: 6px 30px 6px 20px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 0.875rem;
    color: $tertiary-gray-color;
    min-height: 36px;
    appearance: none;
    background-position: 81% center;
    &:focus{
      outline: 1px solid black;
    }
  }
}

.sort-btn svg:nth-child(1) {
  position: absolute;
  top: -11px;
}

.sort-btn svg:nth-child(2) {
  position: absolute;
  top: -3px;
}

.sorting button.sorting_button svg {
  top: -7px !important;

}

.sort-btn {
  position: relative;
}

.full-name{
  white-space: nowrap;
}

// ###########################################  RESPONSIVE TABLE  ################################################################

@media screen and (max-width: 1450px) {
  .sorting span:before {
    bottom: 0px;
  }
}

@media screen and (max-width: 1199px) {
  .customTable{
    width: 1200px !important;
  }
  .dt-responsive {
    th {
      width: auto !important;
    }

    .dataTables_info {
      font-size: 0.875rem;
    }

    .paginate_button {
      font-size: 0.875rem !important;
    }
  }

  .dataTables_wrapper .dataTables_filter input {
    min-width: 200px;
    width: 200px;
  }

  .customTable{
    th,td{
      font-size: 0.875rem !important;
      a{
        font-size: 0.875rem !important;
      }
    }
  }

}

@media screen and (max-width: 991px) {
  .dataTables_wrapper {

    .table_header_data {
      margin-top: 0;
    }

    .dataTables_filter {
      input {
        min-height: 46px;
      }
    }
  }

  .dt-responsive {
    th {
      width: auto !important;
    }

    .customTable {
      width: 1200px !important;
    }

    .dataTables_paginate {
      position: static !important;
      margin: 25px auto auto auto !important;
      display: table;
    }

    .dataTables_info {
      position: static !important;
      display: block !important;
      transform: unset;
      text-align: center;
      min-height: auto;
      font-size: 1rem;
      margin: 20px auto !important;
    }

    .dataTables_length {
      display: block !important;
      position: static !important;
      margin-top: 20px;

      label {
        margin: auto;
      }
    }

    .paginate_button {
      font-size: 1rem !important;
    }
  }

  .dataTables_wrapper .customTable .btn-table .dropdown-menu a {
    font-size: 0.813rem !important;
  }

  .dataTables_wrapper {
    .dataTables_filter {
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 0 0 0;
      padding: 0;
      width: calc(100% - 210px);
      align-items: center;

      label {
        font-size: 1rem;
      }



      input {
        width: 100%;
        margin-left: 25px;
        min-width: auto;

      }


      button.searchbtn {
        right: 20px !important;
        height: 30px;

        svg {
          width: 16px !important;
          height: 16px !important;
        }
      }

      .clear {
        right: 65px !important;
        height: 30px;

        svg {
          width: 22px !important;
          height: 22px !important;
        }
      }
    }

    .custom-btn {
      top: 0;
    }
  }

  .table_header_data {
    justify-content: start !important;
  }

  .pagination-inner-wrapper {
    flex-direction: column;
  }

  .dataTables_wrapper .dataTables_info {
    display: block !important;
    margin: 20px auto !important;
  }
}

@media screen and (max-width: 768px) {
  .dataTables_wrapper {
    padding-bottom: 35px;



    .dt-responsive {
      .customTable {
        width: 900px !important;
      }
    }

    .dataTables_paginate {
      position: static !important;
      margin: 25px auto auto auto !important;
      display: table;
    }

    .dataTables_info {
      position: static !important;
      display: block !important;
      transform: unset;
      text-align: center;
      min-height: auto;
      font-size: 1rem;
      margin: 20px auto !important;
    }

    .dataTables_length {
      display: block !important;
      position: static !important;
      margin-top: 20px;

      label {
        margin: auto;
      }
    }
  }

  .dataTables_wrapper {
    .dataTables_filter {
      margin: 0;
      display: table;
      padding: 0;
      width: calc(100% - 180px);



      input {
        width: 100%;
        margin-left: 25px !important;
        min-width: auto;
      }

    }
  }

  .paginate_button.previous {
    font-size: 0 !important;
  }

  .paginate_button.next {
    font-size: 0 !important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.previous:after {
    top: -10px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.next:after {
    top: -10px;
  }

  .pagination-inner-wrapper {
    flex-direction: column;
  }


  .customTable{
    th,td{
      font-size: 0.875rem !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .mainTitleWrapper {
    text-align: center;
  }

  .center-mobile {
    justify-content: center;
    display: flex;
  }

  .dataTables_wrapper {
    .dataTables_filter {
      width: 100% !important;
      margin-bottom: 15px !important;
      flex-direction: column;


      input {
        margin: 10px 0 0 0 !important;
        padding: 0;
      }


      .searchbtn {
        top: 44px;
      }

      .clear {
        top: 44px;
      }
    }
  }

  .custom-btn {
    position: static;
    width: calc(100% - 30px);
    margin: auto;
  }

  .table_header_data {
    flex-direction: column;

    .btn_group.ms-4.me-2 {
      margin: 0 !important;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }

  .filterSearch{
    margin-bottom: 15px;
  }

}

.row_th {
  text-align: left;
  font-weight: normal;
}

.select_control {
  width: 100%;
}