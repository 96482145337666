body {
  margin: 0;
  padding: 0;
}

.cropper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 400px;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100px;
}

.controls {
  position: absolute;
  bottom: 55px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}
.cropperButton {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #ccc;
  border-bottom: 5px solid #ccc;
  background: #3f51b5;
  width: 100%;
  border-radius: 5px;
  width: 405px;
  margin-left: 10px;
  cursor: pointer;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}
.controls__input {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

// Responsive

@media screen and (max-width: 470px) {
  .controls {
    flex-wrap: wrap;
    bottom: 140px;
  }
  .cropperButton {
    flex-wrap: wrap;
  }
  .crop-container {
    bottom: 200px;
  }
  .cropperButton button.btn {
    width: 100%;
    margin: 8px 0 0 0 !important;
  }
}
