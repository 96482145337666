.card-section {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1.5px solid #e4e6e9;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  padding: 20px 24px;
}
.radio-section-list {
  list-style-type: none;
  padding: 0px;
}
.radio-section-list li {
  display: inline-block;
  padding-right: 25px;
}
.main-card {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1.5px solid #e4e6e9;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  padding: 30px;
}

.input-file .form-control {
  padding: 11px 16px;
}

.form-check-input {
  cursor: pointer;
}
.checkicons {
  margin-right: 5px;
}
.accesbilty-section label {
  font-size: 14px;
  color: #5e5e5e;
}
.border-top-bottom {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.accesbilty-section h5 {
  font-size: 16px;
}

.additional_instruction {
  font-size: 14px;
  color: #5e5e5e;
  padding-left: 1.5em;
}
