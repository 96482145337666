.coupon-input {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .inputRow {
    margin-bottom: 0;
  }
  .apply-btn {
    margin-left: 15px;
    min-height: 60px;
    margin-top: 31px;
  }
}

// Discount Table
.total-discount-table {
  border: 1px solid #e4e6e9 !important;
  overflow: hidden;
  border-radius: 10px;

  .discountTable {
    width: 100%;

    border-collapse: collapse;

    tr {
      &:last-child {
        th,
        td {
          border-bottom: 0 !important;
        }
      }
    }

    th,
    td {
      padding: 25px;
      width: 50%;
      font-size: 1rem;
      border-right: 1px solid #e4e6e9 !important;
      border-bottom: 1px solid #e4e6e9 !important;
      overflow: hidden;
      color: #10232e;
      strong {
        font-weight: 700;
      }
    }

    th {
      background-color: #f5f5f5;
    }
    td {
      border-right: 0 !important;
    }
  }
}

.couponcode-block{
    padding: 60px 20px 20px 20px;
}


// RESPONSIVE

@media screen and (max-width:1102px){
  .coupon-input {
    .apply-btn{
      margin-top: 52px;
    }
  }
}

@media screen and (max-width:991px){
  .coupon-input {
    .apply-btn{
      margin-top: 33px;
    }
  }
}


@media screen and (max-width:767px){
  .coupon-input {
    .apply-btn{
      margin-top: 25px;
    }
  }
}
