.dl_tile_list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
}
.tile_class {
  width: 32.33%;
  margin-left: 1%;
  margin-bottom: 1%;
  padding: 20px 20px 20px 95px;
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1.5px solid #e4e6e9;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  justify-content: center;
  display: flex;
  flex-direction: column;

  dt {
    font-size: 1.15rem;
    color: #0074b2;
    text-transform: capitalize;
    margin-bottom: 8px;
    line-height: 24px;
    font-weight: 700;
  }
  dd {
    margin: 0;
    font-size: 1.15rem;
    color: #10232e;
    line-height: 24px;
    font-weight: 700;
  }
}

.dt_icon {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 116, 178, 0.05);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0074b2;

  svg {
    width: 40px;
  }
}

.dashboardCards {
  .tile_class:nth-child(7),
  .tile_class:nth-child(8),
  .tile_class:nth-child(9),
  .tile_class:nth-child(10) {
    width: 24%;

    dt {
      font-size: 1rem;
      line-height: 18px;
    }
  }
}

// media Query

@media screen and (max-width: 1365px) {
  .dashboardCards {
    .tile_class:nth-child(7),
    .tile_class:nth-child(8),
    .tile_class:nth-child(9),
    .tile_class:nth-child(10) {
      width: 32.33%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .tile_class {
    width: 49% !important;

    .dt_icon {
      width: 60px;
      height: 60px;

      svg {
        width: 30px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .tile_class {
    width: 100% !important;
  }
}
